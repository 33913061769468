import React from "react"

const IconColorFastDelivery = () => (
  <div className="icon-color">
    <svg width="73" height="43" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-14 -28)" fill="none" fillRule="evenodd">
        <path
          d="M32.013 35.275h36.974v4.35H76.6c2.175 0 8.7 7.612 8.7 8.7v3.262H70.075v4.35H53.762c-.724-2.174-2.537-3.262-5.437-3.262-2.9 0-4.894 1.088-5.981 3.263-1.088-2.175-3.081-3.263-5.982-3.263-2.9 0-4.712 1.088-5.437 3.263v2.174H24.4v-8.7h-5.438V41.8h7.613v-6.525h5.438z"
          fillOpacity=".17"
          fill="#ED1C24"
        />
        <rect
          fill="#ED1C24"
          x="19.974"
          y="52.408"
          width="11.447"
          height="2.671"
          rx="1.336"
        />
        <rect
          fill="#ED1C24"
          x="19.974"
          y="36.382"
          width="11.447"
          height="2.671"
          rx="1.336"
        />
        <path
          d="M29.513 48.592h2.862v8.678c1.198-.956 2.674-1.537 4.293-1.537 2.325 0 4.382 1.19 5.697 2.998h.062c1.306-1.808 3.354-2.998 5.688-2.998 2.334 0 4.382 1.19 5.688 2.998h14.345V31.748H32.375v11.12h-2.862v-11.12c0-1.658 1.279-2.998 2.862-2.998h35.773c1.583 0 2.862 1.34 2.862 2.998v6.006h4.785c1.52 0 2.978.627 4.05 1.752l5.233 5.48a6.124 6.124 0 011.672 4.236V60.23c0 .825-.644 1.5-1.43 1.5h-1.575c.09.487.143.983.143 1.498 0 4.142-3.202 7.496-7.154 7.496-3.953 0-7.155-3.354-7.155-7.496 0-.515.045-1.011.143-1.499H55.127c.09.488.143.984.143 1.5 0 4.14-3.202 7.495-7.155 7.495-2.352 0-4.418-1.2-5.724-3.036-1.305 1.827-3.371 3.036-5.723 3.036-3.953 0-7.155-3.354-7.155-7.496V48.592zm7.155 19.134c2.37 0 4.293-2.015 4.293-4.498 0-2.482-1.923-4.497-4.293-4.497s-4.293 2.015-4.293 4.497c0 2.483 1.923 4.498 4.293 4.498zm11.447 0c2.37 0 4.293-2.015 4.293-4.498 0-2.482-1.923-4.497-4.293-4.497s-4.293 2.015-4.293 4.497c0 2.483 1.923 4.498 4.293 4.498zM71.01 40.742v5.997h11.689l-4.883-5.116a2.757 2.757 0 00-2.021-.88H71.01zm5.724 26.984c2.37 0 4.292-2.015 4.292-4.498 0-2.482-1.922-4.497-4.292-4.497s-4.293 2.015-4.293 4.497c0 2.483 1.923 4.498 4.293 4.498zm7.154-8.995v-8.994H71.01v8.994h.036c1.305-1.808 3.353-2.998 5.688-2.998 2.334 0 4.382 1.19 5.687 2.998h1.467z"
          fill="#223247"
        />
        <path
          d="M36.408 51.462c-.335.39-.909.114-.909-.437v-3.66H15.693a1.443 1.443 0 110-2.887H35.5v-3.66c0-.552.574-.828.91-.438l4.395 5.104a.69.69 0 010 .874l-4.396 5.104z"
          fill="#ED1C24"
        />
      </g>
    </svg>
  </div>
)

export default IconColorFastDelivery
