import React from "react"

const IconColor100Nights = () => (
  <div className="icon-color">
    <svg width="72" height="46" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M14.6042 16.0556c-2.9296 0-5.3125 2.2427-5.3125 5 0 2.7572 2.3829 5 5.3125 5s5.3125-2.2428 5.3125-5c0-2.7573-2.383-5-5.3125-5zM65.9583 33.4167V24.736c0-4.7863-3.9721-8.6805-8.8541-8.6805H30.5417v17.361h35.4166z"
          fillOpacity=".17"
          fill="#ED1C24"
        />
        <path
          d="M58.5389 34.8889c.3834-1.466.396-1.3944 1.707-2.729a2.9049 2.9049 0 00.5291-.7432h7.1833V22.736c0-4.7863-3.9721-8.6805-8.8541-8.6805H32.5417v17.361h3.8505c.1346.2708.3119.5222.5289.7432 1.311 1.3347 1.3236 1.2632 1.707 2.729H4.2083v6.0765c0 .4796-.3962.868-.8854.868H1.5521c-.4892 0-.8854-.3884-.8854-.868V1.0347c0-.4796.3962-.868.8854-.868h1.7708c.4892 0 .8854.3884.8854.868v30.382H29V12.3194c0-.9591.7925-1.736 1.7708-1.736h28.3334c6.8465 0 12.3958 5.4404 12.3958 12.1527v18.2292c0 .4796-.3962.868-.8854.868h-1.7708c-.4892 0-.8855-.3884-.8855-.868v-6.0764H58.539zm-41.9347-6.9445c-4.882 0-8.8542-3.8942-8.8542-8.6805 0-4.7863 3.9722-8.6806 8.8542-8.6806 4.882 0 8.8541 3.8943 8.8541 8.6806 0 4.7863-3.9721 8.6805-8.8541 8.6805zm0-13.8888c-2.9296 0-5.3125 2.3361-5.3125 5.2083 0 2.8721 2.3829 5.2083 5.3125 5.2083s5.3125-2.3362 5.3125-5.2083c0-2.8722-2.383-5.2083-5.3125-5.2083z"
          fill="#223247"
        />
        <path
          d="M42.5643 38.8684c.6244.6579 1.454 1.0203 2.3369 1.0203.5633 0 1.108-.169 1.5988-.4574l-2.3568 6.093c-.1988.5142-.8345.6399-1.1968.2369l-1.6409-1.8277-2.3857.096c-.5262.021-.8975-.5376-.6987-1.0518l2.0543-5.311c.3423.2802.7209.5142 1.1515.636.949.2677.744.1508 1.1374.5657zm16.3813 4.1093c.1988.5137-.1725 1.0728-.6986 1.0518l-2.3857-.096-1.6413 1.8277c-.3617.403-.998.2773-1.1967-.2368l-2.3566-6.0931c.4908.2879 1.0355.4574 1.5987.4574.8829 0 1.7128-.3624 2.3372-1.0203.3925-.414.1834-.2965 1.1374-.5658.4305-.1217.809-.3552 1.1513-.636l2.0543 5.3111zm-6.1602-6.0214c-.783.7972-2.0029.9398-2.9434.3445-.318-.202-.8818-.3658-1.2585-.3658-.3767 0-.9406.1638-1.2586.3658-.9411.5953-2.1609.4527-2.944-.3445-.8718-.8877-.9706-.831-2.2952-1.192-.822-.2242-1.4649-.8783-1.685-1.7151-.4428-1.6808-.328-1.4778-1.536-2.7077-.6018-.6131-.8368-1.5062-.6166-2.343.4427-1.682.442-1.4477 0-3.1261-.2208-.8369.0148-1.73.6167-2.3425 1.208-1.2298 1.0938-1.0274 1.5359-2.7077.2201-.8368.863-1.4908 1.685-1.7151 1.6507-.4504 1.4518-.3332 2.6598-1.563.602-.6132 1.4797-.8523 2.3018-.628 1.6524.4504 1.4222.4498 3.0718 0 .822-.2243 1.6998.0154 2.3017.628 1.2091 1.2304 1.0103 1.1126 2.6604 1.563.822.2243 1.4649.8783 1.685 1.7151.4428 1.6803.328 1.4779 1.536 2.7077.6018.6125.8368 1.5056.6166 2.3425-.4426 1.6814-.442 1.447 0 3.1266.2202.837-.0148 1.73-.6167 2.3425-1.208 1.2299-1.0931 1.027-1.5359 2.7077-.2201.8368-.863 1.4909-1.685 1.7152-1.2879.351-1.3914.2716-2.2958 1.192zM49 31.8333c2.3012 0 4.1667-1.8654 4.1667-4.1666 0-2.3012-1.8655-4.1667-4.1667-4.1667-2.3012 0-4.1667 1.8655-4.1667 4.1667 0 2.3012 1.8655 4.1666 4.1667 4.1666z"
          fill="#ED1C24"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </div>
)

export default IconColor100Nights
