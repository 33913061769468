import React from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"

const Quote = ({ quote }) => {
  return (
    <QuoteWrap>
      <Inner>
        <h3>{quote}</h3>
      </Inner>
    </QuoteWrap>
  )
}

const QuoteWrap = styled.div`
  background-color: #223247;

  h3 {
    color: white;
    padding: 60px 0;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 300;
    line-height: 57px;
    text-align: center;

    &::before {
      content: '"';
      color: #ed1c24;
      font-size: 70px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 18px;
      line-height: 36px;
    }
  }
`

export default Quote
