import React from "react"

const IconStarSolid = ({ fill }) => (
  <svg
    width="16px"
    height="15px"
    viewBox="0 0 16 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon" transform="translate(-4.000000, -4.000000)" fill={fill}>
        <path
          d="M11.2152121,5.23674146 C11.5378623,4.58324341 12.4648239,4.59144117 12.7847503,5.23674146 L14.5702876,8.857056 L18.5651893,9.43947115 C19.2815991,9.54337181 19.5687078,10.4265675 19.0491778,10.9324257 L16.158945,13.7488252 L16.8425371,17.7273313 C16.9655837,18.4464916 16.208185,18.9824171 15.5738062,18.6488189 L11.9999812,16.7703024 L8.42615628,18.6488189 C7.79177745,18.9851408 7.03437874,18.4464916 7.15742533,17.7273313 L7.84101745,13.7488252 L4.95078462,10.9324257 C4.43125461,10.4265675 4.7183633,9.54337181 5.43477318,9.43947115 L9.42967485,8.857056 L11.2152121,5.23674146 Z"
          id=""
        ></path>
      </g>
    </g>
  </svg>
)

export default IconStarSolid
