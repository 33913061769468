import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../zzz/layout"
import SEO from "../../components/SEO/seo"
import Quote from "../../components/AboutUs/Quote"
import Heading from "../../zzz/organisms/heading"
import Inner from "../../zzz/layout/pageInner/Inner"
import IconColor100Nights from "../../zzz/atoms/icons/color/icon-color-100-night-trial"
import IconColorDelivery from "../../zzz/atoms/icons/color/icon-color-fast-delivery"
import IconColorLowestPrice from "../../zzz/atoms/icons/color/icon-color-lowest-price"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import StarSolid from "../../zzz/atoms/icons/files/star-solid.jsx"
import { path, getImage } from "../../lib/util"
import dashLine from "../../images/icons/line_dashed.svg"

const AboutPage = ({ location }) => {
  const data = useStaticQuery(PAGE)

  const { aboutUs, OurStory, featuredImage, seo } = data.aboutUsPageData

  return (
    <Layout location={location}>
      <SEO title="Contact Us" location={location} post_id={90} seo={seo} />
      <Heading
        location={location}
        subTitle={aboutUs.aboutUsSubTitle}
        image={getImage(featuredImage)}
      />
      <StyledTimeline>
        <SectionTitle>Timeline</SectionTitle>
        <SectionContainer>
          <RedStrip></RedStrip>
          {OurStory.ourStoryYears.map((year, i) => {
            return (
              <div
                className={"years"}
                key={`${year.ourStoryYearsYearNumber}-${i}`}
              >
                <div className={"timelineIcon"}>
                  <img
                    className="yearIcon"
                    src={year.ourStoryYearsYearIcon.sourceUrl}
                  />
                </div>
                <h3>{year.ourStoryYearsYearNumber}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: year.ourStoryYearsYearContent,
                  }}
                />
                <div className={"dashedLine"}></div>
              </div>
            )
          })}
        </SectionContainer>
      </StyledTimeline>
      <StyledOurStory>
        <SectionTitle>Our Story</SectionTitle>
        <SectionContainer>
          {OurStory.ourStoryInfo.map((item, i) => {
            return (
              <div key={`${item.ourStoryInfoTitle}-${i}`}>
                <h3>{item.ourStoryInfoTitle}</h3>
                <span
                  dangerouslySetInnerHTML={{ __html: item.ourStoryInfoContent }}
                />
              </div>
            )
          })}
        </SectionContainer>
      </StyledOurStory>
      <Quote quote={aboutUs.aboutUsQuote} />
      <Grid>
        <Inner className="innerGrid">
          <IconWrap>
            <div className="icon-wrap">
              <IconColor100Nights />
            </div>
            <div className="body">
              {aboutUs.aboutUsIcons[0].aboutUsIconsBody}
            </div>
          </IconWrap>
          <IconWrap>
            <div className="icon-wrap">
              <IconColorDelivery />
            </div>
            <div className="body">
              {aboutUs.aboutUsIcons[1].aboutUsIconsBody}
            </div>
          </IconWrap>
          <IconWrap>
            <div className="icon-wrap">
              <IconColorLowestPrice />
            </div>
            <div className="body">
              {aboutUs.aboutUsIcons[2].aboutUsIconsBody}
            </div>
          </IconWrap>
        </Inner>
      </Grid>
      <Cta>
        <Inner className="inner">
          <StyledAboutUsCta>{aboutUs.aboutUsCta.title}</StyledAboutUsCta>
          <Button href="mailto:recruitment@themattresswarehouse.co.za">
            <div>{aboutUs.aboutUsCta.action.title}</div>
            <SvgIcon
              SvgComponent={StarSolid}
              hue="danger"
              size="md"
              shade="fontColor"
            />
          </Button>
        </Inner>
      </Cta>
    </Layout>
  )
}

export default AboutPage

const StyledAboutUsCta = styled.div`
  font-size: 2.4375rem;
  font-weight: 300;
  line-height: 1.5;
  font-style: normal;
`

const Cta = styled.div`
  background: #f6f6f6;
  padding: 60px 0;
  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const Button = styled.a`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
  }

  > div {
    padding: 0 15px;
    font-size: 20px;
  }

  > span {
    padding: 8px;
    background: #da2e32;
  }
`

const IconWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-wrap {
    padding: 75px;
    background: #f6f6f6;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 40px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin-bottom: 10px;
      padding: 55px;
    }
  }

  &:nth-of-type(even) .icon-wrap {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      background: #ffffff;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      background: #ffffff;
    }
  }

  &:nth-of-type(even) {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      background: #f6f6f6;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      background: #f6f6f6;
    }
  }

  &:nth-of-type(odd) {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      background: #ffffff;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      background: #ffffff;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 30px 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding: 30px 10px;
  }

  .body {
    text-align: center;
  }
`

const SectionContainer = styled.div`
  position: relative;
  width: 80%;
  min-width: 300px;
  max-width: 1240px;
  margin: auto;
  padding: 20px;
  div {
    padding: 10px;
    h3 {
      font-weight: 500;
    }
  }
  @media screen and (max-width: 790px) {
    width: 100%;
    padding: 10px;
  }
`

const RedStrip = styled.div`
  position: absolute;
  height: 100%;
  background: #ed1c24;
  width: 230px;
  top: 0;
  left: 90px;
  z-index: -1;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const SectionTitle = styled.h2`
  background-color: #223247;
  text-align: center;
  color: white;
  margin: auto;
  padding: 20px;
`

const StyledTimeline = styled.div`
  h3 {
    font-size: 400%;
    text-align: left;
    color: white;
  }
  p {
    margin-left: 50px;
    text-align: left;
    align-self: center;
  }
  .years {
    display: flex;
    align-items: center;
    margin: 40px 0;
    position: relative;
  }
  .timelineIcon {
    background: rgba(255, 255, 255, 1);
    border: 7px solid white;
    min-width: 100px;
    min-height: 100px;
    border-radius: 50px;
    // padding: 15px;
    align-self: center;
    margin-right: 20px;
    display: flex;
    align-items: center;
    .yearIcon {
      width: 65px;
      height: 65px;
    }
  }
  .dashedLine {
    position: absolute;
    background: url(${dashLine}) no-repeat;
    height: 3px;
    width: 100px;
    bottom: -40px;
    left: 250px;
  }
  @media screen and (max-width: 600px) {
    h3 {
      font-size: 300%;
      text-align: center;
      font-weight: bold;
      color: #ed1c24;
      margin: 10px 0;
    }
    p {
      margin-left: 0;
      text-align: center;
      align-self: center;
    }
    .years {
      flex-direction: column;
    }
    .dashedLine {
      display: none;
    }
    .timelineIcon {
      margin-right: 0;
      border: 2px solid #ed1c24;
      padding: 20px;
      border-radius: 60px;
      align-content: center;
    }
  }
`

const StyledOurStory = styled.div`
  background: #ffffff;
  h3 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &:before {
      display: block;
      content: "";
      background: #ed1c24;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 15px;
    }
  }
`

const Grid = styled.div`
  padding: 60px 0;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 0;
  }

  .inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0px;
    }
  }

  .innerGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0px;
    }

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      padding: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      padding: 0;
    }
  }
`

const PAGE = graphql`
  {
    aboutUsPageData: localWpGraphQlPages(slug: { eq: "about-us" }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      OurStory {
        ourStoryInfo {
          ourStoryInfoTitle
          ourStoryInfoContent
        }
        ourStoryYears {
          ourStoryYearsYearNumber
          ourStoryYearsYearContent
          ourStoryYearsYearIcon {
            sourceUrl
          }
        }
      }
      aboutUs {
        aboutUsSubTitle
        aboutUsContentRow {
          aboutUsBody
          aboutUsImage {
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
        aboutUsQuote
        aboutUsIcons {
          aboutUsIconsBody
        }
        aboutUsCta {
          title
          action {
            title
            link
          }
        }
      }
      featuredImage {
        sourceUrl
        mediaItemId
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
